import React from "react";
import { HashLink } from 'react-router-hash-link';
import "../index.css";

function TableOfContents() {
    return (
        <div className="toc__list">
            <ul className="toc__list--ul">
                <li className="toc__item"><HashLink className="toc__link" to="#purpose">Project Purpose and Goals</HashLink></li>
                <li className="toc__item"><HashLink className="toc__link" to="#features">Features and Functionalities</HashLink></li>
                <li className="toc__item"><HashLink className="toc__link" to="#tools">Tools Used and Explanation</HashLink></li>
            </ul>
        </div>
    )
}
export default TableOfContents;